<div class="bg-blue">
  <div class="m-w-1200 mx-auto flex space-between py-4 text-white">
    <div class="flex gap-16">
      <span>johnDoe&#64;nhs.net</span>
      @if (role) {
      <span>{{ transformRole(role) }}</span>
      }
    </div>
    <div class="flex gap-16">
      @if (role) {
      <a routerLink="/role">Switch role</a>
      }
      <button class="nhsuk-link" (click)="signOut()">Sign out</button>
    </div>
  </div>
</div>
