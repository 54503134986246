
<aside>
    <div class="nhsuk-width-container-fluid m-w-1200 mx-auto">
        <hr>
        <h2>Disclaimer </h2>
        <p>All personal confidential data held within this portal is protected by Data Protection and Confidentiality law. 
        Users of the portal must only access records where they have a legitimate reason to do so and no information 
        should be shared with any individual who does not have authorisation to access it.</p>

        <p>It is your responsibility to be aware of your lawful obligations and your own Organisational Policies and Procedures 
        in relation to Data Protection, Confidentiality and Information Security.</p>
    </div>
</aside>

