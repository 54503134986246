import { Routes } from '@angular/router';
import { authGuard, roleGuard } from './guards';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then(
        (component) => component.LoginComponent
      ),
  },
  {
    path: 'role',
    loadComponent: () =>
      import('./pages/role/role.component').then(
        (Component) => Component.RoleComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: ':role/home',
    loadComponent: () =>
      import('./pages/home/home.component').then(
        (component) => component.HomeComponent
      ),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: 'accessibility',
    loadComponent: () =>
      import('./pages/accessibility/accessibility.component').then(
        (component) => component.AccessibilityComponent
      ),
   
  },
  {
    path: 'feedback',
    loadComponent: () =>
      import('./pages/feedback/feedback.component').then(
        (component) => component.FeedbackComponent
      ),
   
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./pages/cookies/cookies.component').then(
        (component) => component.CookiesComponent
      ),
   
  },
  {
    path: 'info',
    loadComponent: () =>
      import('./pages/info/info.component').then(
        (component) => component.InfoComponent
      ),
   
  },
  {
    path: 'terms',
    loadComponent: () =>
      import('./pages/terms/terms.component').then(
        (component) => component.TermsComponent
      ),
   
  },
  {
    path: ':role/search',
    loadComponent: () =>
      import('./pages/search/search.component').then(
        (Component) => Component.SearchComponent
      ),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: ':role/search/patient/:guid',
    loadComponent: () =>
      import('./pages/patient-record/patient-record.component').then(
        (Component) => Component.PatientRecordComponent
      ),
    canActivate: [authGuard, roleGuard],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (Component) => Component.NotFoundComponent
      ),
  },
];
