import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StatusBarComponent } from '../status-bar/status-bar.component';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { AuthService } from '../../services';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';

@Component({
  selector: 'app-layout',
  imports: [
    RouterOutlet,
    FooterComponent,
    DisclaimerComponent,
    HeaderComponent,
    NgIf,
    StatusBarComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
})
export class LayoutComponent {
  @Input() isIframe = false;

  constructor(private _authService: AuthService) {}

  isLogged() {
    return this._authService.isLoggedIn();
  }
}
