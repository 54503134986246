<footer>
  <div class="nhsuk-footer-container">
    <div class="nhsuk-width-container-fluid m-w-1200 mx-auto">
      <h2 class="nhsuk-u-visually-hidden">Support links</h2>
      <div class="nhsuk-footer">
        <ul class="nhsuk-footer__list">
          @for (navItem of footerNavigation; track $index) {
          <li class="nhsuk-footer__list-item nhsuk-footer-default__list-item">
            <a class="nhsuk-footer__list-item-link" href="{{ navItem.url  }}">{{ navItem.name }}</a>
          </li>
          }
        </ul>

        <div>
          <p class="nhsuk-footer__copyright">© NHS England</p>
        </div>
      </div>
    </div>
  </div>
</footer>
