import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { filter } from 'rxjs';

import { mainNavigation } from '../../constants/index';
import { AuthService } from '../../services';

enum Role {
  GP = 'gp',
  HealthVisitor = 'health-visitor',
  SchoolImmunisationTeam = 'school-immunisation-team',
}

@Component({
  selector: 'app-header',
  imports: [RouterLinkWithHref],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  mainNavigation = mainNavigation;
  role: Role | null = null;

  constructor(private _router: Router, private _authService: AuthService) {}

  ngOnInit() {
    this.updateRoleFromUrl(this._router.url);

    const events = this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    );

    events.subscribe((event: NavigationEnd) => {
      this.updateRoleFromUrl(event.url);
    });
  }

  updateRoleFromUrl(url: string) {
    const urlSegments = url.split('/');
    const roleFromUrl = urlSegments[1] as Role;

    if (this.isValidRole(roleFromUrl)) {
      this.role = roleFromUrl as Role;
    } else {
      this.role = null;
    }
  }

  isValidRole(value: Role): value is Role {
    return Object.values(Role).includes(value);
  }
}
