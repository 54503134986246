import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

enum Role {
  GP = 'gp',
  HealthVisitor = 'health-visitor',
  SchoolImmunisationTeam = 'school-immunisation-team',
}

export const roleGuard: CanActivateFn = (route, state) => {
  const urlSegments = state.url.split('/');
  const roleFromUrl = urlSegments[1] as Role;

  const router = inject(Router);

  if (isValidRole(roleFromUrl)) {
    return true;
  } else {
    console.log('Invalid role');
    router.navigateByUrl('/role');
    return false;
  }

  function isValidRole(value: Role): value is Role {
    return Object.values(Role).includes(value);
  }
};
