export const mainNavigation = [
  {
    name: 'Home',
    url: '/home',
  },
  {
    name: 'Search',
    url: '/search',
  },
  {
    name: 'Immunisation Report',
    url: '/immunisation-report',
  },
  {
    name: 'Health Inequalities',
    url: '/health-inequalities',
  },
  {
    name: 'Screening Completness',
    url: '/screening-completness',
  },
];

export const footerNavigation = [
  {
    name: 'CHIS Website',     
    url: 'https://chis.scwcsu.nhs.uk/',
  },
  {
    name: 'Information & resources',
    url: 'info',
  },
  {
    name: 'Leave us a feedback',
    url: 'feedback',
  },
  {
    name: 'Accessibility statement',
    url: 'accessibility',
  },
  {
    name: 'Terms and conditions',
    url: 'terms',
  },
  {
    name: 'Cookies',
    url: 'cookies',
  },
];
