<a class="nhsuk-skip-link" href="#maincontent">Skip to main content</a>
<app-status-bar *ngIf="isLogged()" />
<div class="app-container">
  <app-header />
  <main class="nhsuk-main-wrapper py-0" id="maincontent">
    <router-outlet *ngIf="!isIframe" />
  </main>
  <div class="disclaimer-container">
    <app-disclaimer />
  </div>
  <div class="footer-container">
    <app-footer />
  </div>
</div>
